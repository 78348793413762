export default function Purpose() {
  return (
    <section
      id="sobre"
      className="text-text flex w-full flex-col items-center bg-background py-12 md:py-20"
    >
      <div className="mx-auto flex max-w-6xl flex-col px-4 sm:px-6 md:flex-row">
        <div className="mx-auto max-w-3xl">
          <h2 className="font-heading mb-4 text-end text-3xl font-bold md:text-4xl">Propósito</h2>
          <p className="text-end text-xl">
            Nosso propósito é levar além do paisagismo convencional, é transformar seu espaço e
            integra-lo a natureza em uma proposta sustentável, dando preferência à plantas nativas,
            que além de embelezar e ajudam na reconstrução do nosso ecossistema.
          </p>
        </div>
      </div>
    </section>
  )
}
